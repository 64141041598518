import React, { useEffect, useRef } from "react";
import ReactHtmlParser from "html-react-parser";

import * as styles from "./PostContent.module.scss";
import IPostContent from "./PostContent.type";

const PostContent = ({ content }: IPostContent) => {
    const articleRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio >= 0) {
                    {
                        const img = entry.target as HTMLImageElement;
                        img.src = img.dataset.src || img.src;
                        img.srcset = img.dataset.srcset || img.srcset || "";
                        img.style.opacity = "1";

                        observer.unobserve(img);
                    }
                }
            });
        });

        if (articleRef.current) {
            const imgElements: HTMLImageElement[] = Array.from(
                articleRef.current.getElementsByTagName("img")
            );

            imgElements.forEach((img) => {
                observer.observe(img);
            });
        }

        return () => observer.disconnect();
    }, []);

    return (
        <section className={styles.section}>
            <div className="container">
                <article className={styles.content} ref={articleRef}>
                    {/* {ReactHtmlParser(content, {
                        transform: (x) => {
                            console.log(x);
                            return x?.data;
                        },
                    })} */}
                    {ReactHtmlParser(content)}
                </article>
            </div>
        </section>
    );
};

export default PostContent;
